import * as React from "react"
import Layout from '../components/Layout'
import { css } from "@emotion/react";
import Container from "../components/Container";
import Title from "../components/TitleComponent";


const VirtualPage = () => {
  return(
     <div css={three}>
      <Layout title="Adarsh Park Heights | Adarsh Park Heights Virtual Tour">
      <Container>
        <Title title="Virtual Site Tour" size="3vw" color="#8869d3" />
          <p>
            Book a pair of & three BHK residences with world category amenities
            at Adarsh Park Heights in Whitefield. on the brink of Offices
            faculties & amusement | speedily Progressing Construction | Visit
            these days. Virtual Tour out there. transfer Floor Plans. speedy
            Construction. Best-in-Class Amenities..
          </p>
        </Container>
      </Layout>
     </div>
  )
}

export default VirtualPage

const three = css`
  padding-top:10vh;
`;
